import React from 'react'
import './App.css'

import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { RiotGames } from 'components/RiotGames'
import { Selection } from 'components/Selection'
import { Imprint } from 'components/Imprint'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { Activision } from 'components/Activision'
import { EpicGames } from 'components/EpicGames'

const App = () => {
  const location = useLocation()
  return (
    <>
      <Header />
      <Routes>
        <Route path={'/riotgames'} element={<RiotGames />} />
        <Route path={'/activision'} element={<Activision />} />
        <Route path={'/epicgames'} element={<EpicGames />} />
        <Route path={'/imprint'} element={<Imprint />} />
        <Route path={'/'} element={<Selection />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {location.pathname !== '/imprint' && <Footer />}
    </>
  )
}

export default App
