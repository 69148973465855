import React, { useEffect, useState } from 'react'
import { Text, Image } from '@chakra-ui/react'

interface TwitchChatPreviewDef {
  text: string,
}

export const TwitchChatPreview = ({ text }: TwitchChatPreviewDef) => {

  const [time, setTime] = useState(new Date().toLocaleString(navigator.language, { hour: '2-digit', minute: '2-digit' }))

  // HAHAHAHA KEHU
  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date().toLocaleString(navigator.language, { hour: '2-digit', minute: '2-digit' }))
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Text>
      <Text as='span' color='#53535f'>{time}</Text>
      <Image mb={-1} height='20px' width='20px' mx='1' display='inline' src='https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/1' />
      <Text as='span' color='#dc0000' fontWeight={700}>TwitchBot: </Text>
      <Text as='span' color='#0e0e10'>{text}</Text>
    </Text>
  )
}