import { css } from '@emotion/css'

export const container = css`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
`

export const flex = css`
  display: flex;
`

export const footerLink = css`
  cursor: pointer;
  transition: linear .2s all;
  color: #ccc;
  font-weight: 400;
  &:hover {
    transition: linear .2s all;
    color: #fff;
    font-weight: 600;
    /* border-bottom: 1px solid white; */
  }
`

export const typist_inline = css`
  display: inline;
` 

export const contact_button = css`
  background: linear-gradient(to right, #DE5F7A, #7558E6) !important;
`

export const white_font = css`
  color: white;
`

export const tabs = css`
  button {
    outline: none !important;
    box-shadow: none;
    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }
`