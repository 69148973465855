import i18n from "i18next"
import { initReactI18next } from "react-i18next"

// Importing translation files
// import * as translateObjEN from "./translations/en"
// import * as translateObjDE from "./translations/de"

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: {
      LANDINGPAGE_PRE_HEADER: 'Gaming career statistics',
      LANDINGPAGE_SLOGAN: 'Your gaming career for Twitch Bots',
      LANDINGPAGE_SLOGAN_DESCRIPTION: 'We provide your gaming career statistics of your favorite games for your Twitchbot. Full customizable for sure.',
      CONTACT_US: 'CONTACT US',
      RIOT_GAMES: 'Riot Games',
      ACTIVISON_BLIZZARD: 'Activision Blizzard',
      EPIC_GAMES: 'Epic Games',
      WHY_XYZ_QUESTION: 'And before you ask why our websites stats4u ends with <strong>.xyz</strong>',
      WHY_XYZ_ANSWER: '✨ It\'s fancy - thats why ✨',
      RIGHTS_RESERVED: 'All rights reserved.',
      IMPRINT: 'Imprint',
      CONTACT: 'Contact',
      SUPPORT_SOON: 'Support service will be available soon',
      TWITCH_GIF_ALT_TEXT: 'Chatbot example animation',

      MISSING_API_INFO_NO_REPLACE: 'Important: If you haven\'t played games in the specific game type, the text blocks will not be replaced in the result.',

      // LOL
      SUMMONER_NAME: 'Summoner name',
      BOT_SOFTWARE: 'Bot software',
      LOL: 'League of Legends',
      TFT: 'Teamfight Tactics',
      TEXT_BLOCKS: 'Text blocks',
      TEXT_BLOCKS_INFO_1: 'Use this text blocks to build your output message. The output message will be filled with',
      TEXT_BLOCKS_INFO_2: 'example data',
      TEXT_BLOCKS_INFO_3: 'To check the result of your account, click "Generate" and paste the value of the "Result" input inside your bot command or click on "Check".',

      GENERATE: 'Generate',
      OUTPUT: 'Output',
      GENERATED_CONTENT_INFO: 'You can add additional text here, to customize the output according to your wishes.\nIMPORTANT: The variables must not be changed.',
      OUTPUT_INFO_1: 'This is how it would look in your chat with',
      OUTPUT_INFO_2: 'example data',
      RESULT: 'Result',
      RESULT_INFO: 'Insert this result as the value for your bot command.',
      RESULT_COPY: 'Copy',
      RESULT_COPY_TITLE: 'Click here to copy the result to easily insert it in your bot value.',
      RESULT_CHECK: 'Check',
      RESULT_CHECK_TITLE: 'Click here to check your result in the browser',

      CLIPBOARD_COPIED: 'Copied to clipboard.',
      CLIPBOARD_COPIED_INFO: 'The result was copied to your clipboard',

      // LOL TAGS
      'soloq-elo_DESC': 'SoloQ Elo',
      'soloq-wins_DESC': 'SoloQ wins',
      'soloq-losses_DESC': 'SoloQ losses',
      'soloq-winrate_DESC': 'SoloQ winrate',
      'soloq-games_DESC': 'SoloQ games',
      'flexq-elo_DESC': 'FlexQ Elo',
      'flexq-wins_DESC': 'FlexQ wins',
      'flexq-losses_DESC': 'FlexQ losses',
      'flexq-winrate_DESC': 'FlexQ winrate',
      'flexq-games_DESC': 'FlexQ games',
      'summoner-name_DESC': 'Summoner name',

      'soloq-elo_INFO': 'Your SoloQ elo',
      'soloq-wins_INFO': 'Number of won games in soloq',
      'soloq-losses_INFO': 'Lost games in soloq',
      'soloq-winrate_INFO': 'Winrate in all played games in soloq',
      'soloq-games_INFO': 'Numbers of played games in soloq',
      'flexq-elo_INFO': 'Your FlexQ elo',
      'flexq-wins_INFO': 'Number of won games in flexq',
      'flexq-losses_INFO': 'Lost games in flexq',
      'flexq-winrate_INFO': 'Winrate in all played games in flexq',
      'flexq-games_INFO': 'Number of played games in flexq',
      'summoner-name_INFO': 'Your ingame name',

      // ACTIVISION
      PROFILE_ID: 'Profile ID',
      REGION: 'Region',
      STARCRAFT2: 'StarCraft II',


      // For the tags below _DESC is for the badge, _INFO is for the tooltips

      // TFT
      'tft-elo_DESC': 'Elo',
      'tft-wins_DESC': 'Wins',
      'tft-losses_DESC': 'Losses',
      'tft-winrate_DESC': 'Winrate',
      'tft-games_DESC': 'Games',
      'tft-elo_INFO': 'Your TFT Elo',
      'tft-wins_INFO': 'Games winned',
      'tft-losses_INFO': 'Games loosed',
      'tft-winrate_INFO': 'Procentual winrate',
      'tft-games_INFO': 'Amount of games played',

      // SC2
      '1v1-rank_DESC': '1v1 Rank',
      '1v1-winrate_DESC': '1v1 Winrate',
      '1v1-games_DESC': '1v1 Games',
      '1v1-wins_DESC': '1v1 Wins',
      'profile-name_DESC': 'Profile name',
      'clan-name_DESC': 'Clan name',

      '1v1-rank_INFO': 'Your rank',
      '1v1-winrate_INFO': 'Your Winrate',
      '1v1-games_INFO': 'Number of played games',
      '1v1-wins_INFO': 'Number of won games',
      'profile-name_INFO': 'Your Profile name',
      'clan-name_INFO': 'Name of your clan',

      // Overwatch
      'rating_DESC': 'Rating',
      'prestige_DESC': 'Prestige',
      'level_DESC': 'Level',
      'games_DESC': 'Games',
      'won_DESC': 'Won',
      'medals-bronze_DESC': 'Bronze medals',
      'medals-silver_DESC': 'Silver medals',
      'medals-gold_DESC': 'Gold medals',
      'medals_DESC': 'Medals',

      'rating_INFO': 'Rating',
      'prestige_INFO': 'Prestige',
      'level_INFO': 'Level',
      'games_INFO': 'Number of played games',
      'won_INFO': 'Number of won games',
      'medals-bronze_INFO': 'Number of collected bronze medals',
      'medals-silver_INFO': 'Number of collected Silver medals',
      'medals-gold_INFO': 'Number of collected gold medals',
      'medals_INFO': 'Number of collected medals',

      'BATTLE_TAG': 'Battle tag',

      // FORTNITE
      'All': 'All',
      'Computer': 'Computer',
      'Console': 'Console',
      'Mobile': 'Mobile',

      'Overall': 'Overall',
      'Solo': 'Solo',
      'Duo': 'Duo',
      'Squad': 'Squad',
      'PLAYERS_NAME': 'Players name',

      // FORTNITE Tags
      'all-overall-games_DESC': 'Games',
      'all-overall-wins_DESC': 'Wins',
      'all-overall-top3_DESC': 'Top3',
      'all-overall-kills_DESC': 'Kills',
      'all-overall-per-min-kills_DESC': 'Kills per minute',
      'all-overall-per-match-kills_DESC': 'Kills per match',
      'all-overall-winrate_DESC': 'Winrate',
      'all-solo-games_DESC': 'Games',
      'all-solo-wins_DESC': 'Wins',
      'all-solo-top3_DESC': 'Top3',
      'all-solo-kills_DESC': 'Kills',
      'all-solo-per-min-kills_DESC': 'Kills per minute',
      'all-solo-per-match-kills_DESC': 'Kills per match',
      'all-solo-winrate_DESC': 'Winrate',
      'all-duo-games_DESC': 'Games',
      'all-duo-wins_DESC': 'Wins',
      'all-duo-top3_DESC': 'Top3',
      'all-duo-kills_DESC': 'Kills',
      'all-duo-per-min-kills_DESC': 'Kills per minute',
      'all-duo-per-match-kills_DESC': 'Kills per match',
      'all-duo-winrate_DESC': 'Winrate',
      'all-squad-games_DESC': 'Games',
      'all-squad-wins_DESC': 'Wins',
      'all-squad-top3_DESC': 'Top3',
      'all-squad-kills_DESC': 'Kills',
      'all-squad-per-min-kills_DESC': 'Kills per minute',
      'all-squad-per-match-kills_DESC': 'Kills per match',
      'all-squad-winrate_DESC': 'Winrate',
      'computer-overall-games_DESC': 'Games',
      'computer-overall-wins_DESC': 'Wins',
      'computer-overall-top3_DESC': 'Top3',
      'computer-overall-kills_DESC': 'Kills',
      'computer-overall-per-min-kills_DESC': 'Kills per minute',
      'computer-overall-per-match-kills_DESC': 'Kills per match',
      'computer-overall-winrate_DESC': 'Winrate',
      'computer-solo-games_DESC': 'Games',
      'computer-solo-wins_DESC': 'Wins',
      'computer-solo-top3_DESC': 'Top3',
      'computer-solo-kills_DESC': 'Kills',
      'computer-solo-per-min-kills_DESC': 'Kills per minute',
      'computer-solo-per-match-kills_DESC': 'Kills per match',
      'computer-solo-winrate_DESC': 'Winrate',
      'computer-duo-games_DESC': 'Games',
      'computer-duo-wins_DESC': 'Wins',
      'computer-duo-top3_DESC': 'Top3',
      'computer-duo-kills_DESC': 'Kills',
      'computer-duo-per-min-kills_DESC': 'Kills per minute',
      'computer-duo-per-match-kills_DESC': 'Kills per match',
      'computer-duo-winrate_DESC': 'Winrate',
      'computer-squad-games_DESC': 'Games',
      'computer-squad-wins_DESC': 'Wins',
      'computer-squad-top3_DESC': 'Top3',
      'computer-squad-kills_DESC': 'Kills',
      'computer-squad-per-min-kills_DESC': 'Kills per minute',
      'computer-squad-per-match-kills_DESC': 'Kills per match',
      'computer-squad-winrate_DESC': 'Winrate',
      'console-overall-games_DESC': 'Games',
      'console-overall-wins_DESC': 'Wins',
      'console-overall-top3_DESC': 'Top3',
      'console-overall-kills_DESC': 'Kills',
      'console-overall-per-min-kills_DESC': 'Kills per minute',
      'console-overall-per-match-kills_DESC': 'Kills per match',
      'console-overall-winrate_DESC': 'Winrate',
      'console-solo-games_DESC': 'Games',
      'console-solo-wins_DESC': 'Wins',
      'console-solo-top3_DESC': 'Top3',
      'console-solo-kills_DESC': 'Kills',
      'console-solo-per-min-kills_DESC': 'Kills per minute',
      'console-solo-per-match-kills_DESC': 'Kills per match',
      'console-solo-winrate_DESC': 'Winrate',
      'console-duo-games_DESC': 'Games',
      'console-duo-wins_DESC': 'Wins',
      'console-duo-top3_DESC': 'Top3',
      'console-duo-kills_DESC': 'Kills',
      'console-duo-per-min-kills_DESC': 'Kills per minute',
      'console-duo-per-match-kills_DESC': 'Kills per match',
      'console-duo-winrate_DESC': 'Winrate',
      'console-squad-games_DESC': 'Games',
      'console-squad-wins_DESC': 'Wins',
      'console-squad-top3_DESC': 'Top3',
      'console-squad-kills_DESC': 'Kills',
      'console-squad-per-min-kills_DESC': 'Kills per minute',
      'console-squad-per-match-kills_DESC': 'Kills per match',
      'console-squad-winrate_DESC': 'Winrate',
      'mobile-overall-games_DESC': 'Games',
      'mobile-overall-wins_DESC': 'Wins',
      'mobile-overall-top3_DESC': 'Top3',
      'mobile-overall-kills_DESC': 'Kills',
      'mobile-overall-per-min-kills_DESC': 'Kills per minute',
      'mobile-overall-per-match-kills_DESC': 'Kills per match',
      'mobile-overall-winrate_DESC': 'Winrate',
      'mobile-solo-games_DESC': 'Games',
      'mobile-solo-wins_DESC': 'Wins',
      'mobile-solo-top3_DESC': 'Top3',
      'mobile-solo-kills_DESC': 'Kills',
      'mobile-solo-per-min-kills_DESC': 'Kills per minute',
      'mobile-solo-per-match-kills_DESC': 'Kills per match',
      'mobile-solo-winrate_DESC': 'Winrate',
      'mobile-duo-games_DESC': 'Games',
      'mobile-duo-wins_DESC': 'Wins',
      'mobile-duo-top3_DESC': 'Top3',
      'mobile-duo-kills_DESC': 'Kills',
      'mobile-duo-per-min-kills_DESC': 'Kills per minute',
      'mobile-duo-per-match-kills_DESC': 'Kills per match',
      'mobile-duo-winrate_DESC': 'Winrate',
      'mobile-squad-games_DESC': 'Games',
      'mobile-squad-wins_DESC': 'Wins',
      'mobile-squad-top3_DESC': 'Top3',
      'mobile-squad-kills_DESC': 'Kills',
      'mobile-squad-per-min-kills_DESC': 'Kills per minute',
      'mobile-squad-per-match-kills_DESC': 'Kills per match',
      'mobile-squad-winrate_DESC': 'Winrate',

      'all-overall-games_INFO': 'Number of played games overall. All platforms added together.',
      'all-overall-wins_INFO': 'Wins overall. All platforms added together.',
      'all-overall-top3_INFO': 'Top3 overall. All platforms added together.',
      'all-overall-kills_INFO': 'Kills overall. All platforms added together.',
      'all-overall-per-min-kills_INFO': 'Kills per minute overall. All platforms added together.',
      'all-overall-per-match-kills_INFO': 'Kills per match overall. All platforms added together.',
      'all-overall-winrate_INFO': 'Winrate overall. All platforms added together.',
      'all-solo-games_INFO': 'Number of played solo games overall. All platforms added together.',
      'all-solo-wins_INFO': 'Solo wins overall. All platforms added together.',
      'all-solo-top3_INFO': 'Solo top3 overall. All platforms added together.',
      'all-solo-kills_INFO': 'Solo kills overall. All platforms added together.',
      'all-solo-per-min-kills_INFO': 'Solo kills per minute overall. All platforms added together.',
      'all-solo-per-match-kills_INFO': 'Solo kills per match overall. All platforms added together.',
      'all-solo-winrate_INFO': 'Solo winrate overall. All platforms added together.',
      'all-duo-games_INFO': 'Number of played duo games overall. All platforms added together.',
      'all-duo-wins_INFO': 'Duo wins overall. All platforms added together.',
      'all-duo-top3_INFO': 'Duo top3 overall. All platforms added together.',
      'all-duo-kills_INFO': 'Duo kills overall. All platforms added together.',
      'all-duo-per-min-kills_INFO': 'Duo kills per minute overall. All platforms added together.',
      'all-duo-per-match-kills_INFO': 'Duo kills per match overall. All platforms added together.',
      'all-duo-winrate_INFO': 'Duo winrate overall. All platforms added together.',
      'all-squad-games_INFO': 'Number of played squad games overall. All platforms added together.',
      'all-squad-wins_INFO': 'Squad wins overall. All platforms added together.',
      'all-squad-top3_INFO': 'Suqad top3 overall. All platforms added together.',
      'all-squad-kills_INFO': 'Squad kills overall. All platforms added together.',
      'all-squad-per-min-kills_INFO': 'Squad kills per minute overall. All platforms added together.',
      'all-squad-per-match-kills_INFO': 'Squad kills per match overall. All platforms added together.',
      'all-squad-winrate_INFO': 'Sqaud winrate overall. All platforms added together.',
      'computer-overall-games_INFO': 'Number of played games overall. Played on computer.',
      'computer-overall-wins_INFO': 'Wins overall. Played on computer.',
      'computer-overall-top3_INFO': 'Top3 overall. Played on computer.',
      'computer-overall-kills_INFO': 'Kills overall. Played on computer.',
      'computer-overall-per-min-kills_INFO': 'Kills per minute overall. Played on computer.',
      'computer-overall-per-match-kills_INFO': 'Kills per match overall. Played on computer.',
      'computer-overall-winrate_INFO': 'Winrate overall. Played on computer.',
      'computer-solo-games_INFO': 'Number of played solo games overall. Played on computer.',
      'computer-solo-wins_INFO': 'Solo wins overall. Played on computer.',
      'computer-solo-top3_INFO': 'Solo top3 overall. Played on computer.',
      'computer-solo-kills_INFO': 'Solo kills overall. Played on computer.',
      'computer-solo-per-min-kills_INFO': 'Solo kills per minute overall. Played on computer.',
      'computer-solo-per-match-kills_INFO': 'Solo kills per match overall. Played on computer.',
      'computer-solo-winrate_INFO': 'Solo winrate overall. Played on computer.',
      'computer-duo-games_INFO': 'Number of played duo games overall. Played on computer.',
      'computer-duo-wins_INFO': 'Duo wins overall. Played on computer.',
      'computer-duo-top3_INFO': 'Duo top3 overall. Played on computer.',
      'computer-duo-kills_INFO': 'Duo kills overall. Played on computer.',
      'computer-duo-per-min-kills_INFO': 'Duo kills per minute overall. Played on computer.',
      'computer-duo-per-match-kills_INFO': 'Duo kills per match overall. Played on computer.',
      'computer-duo-winrate_INFO': 'Duo winrate overall. Played on computer.',
      'computer-squad-games_INFO': 'Number of played squad games overall. Played on computer.',
      'computer-squad-wins_INFO': 'Squad wins overall. Played on computer.',
      'computer-squad-top3_INFO': 'Suqad top3 overall. Played on computer.',
      'computer-squad-kills_INFO': 'Squad kills overall. Played on computer.',
      'computer-squad-per-min-kills_INFO': 'Squad kills per minute overall. Played on computer.',
      'computer-squad-per-match-kills_INFO': 'Squad kills per match overall. Played on computer.',
      'computer-squad-winrate_INFO': 'Sqaud winrate overall. Played on computer.',
      'console-overall-games_INFO': 'Number of played games overall. Played on console.',
      'console-overall-wins_INFO': 'Wins overall. Played on console.',
      'console-overall-top3_INFO': 'Top3 overall. Played on console.',
      'console-overall-kills_INFO': 'Kills overall. Played on console.',
      'console-overall-per-min-kills_INFO': 'Kills per minute overall. Played on console.',
      'console-overall-per-match-kills_INFO': 'Kills per match overall. Played on console.',
      'console-overall-winrate_INFO': 'Winrate overall. Played on console.',
      'console-solo-games_INFO': 'Number of played solo games overall. Played on console.',
      'console-solo-wins_INFO': 'Solo wins overall. Played on console.',
      'console-solo-top3_INFO': 'Solo top3 overall. Played on console.',
      'console-solo-kills_INFO': 'Solo kills overall. Played on console.',
      'console-solo-per-min-kills_INFO': 'Solo kills per minute overall. Played on console.',
      'console-solo-per-match-kills_INFO': 'Solo kills per match overall. Played on console.',
      'console-solo-winrate_INFO': 'Solo winrate overall. Played on console.',
      'console-duo-games_INFO': 'Number of played duo games overall. Played on console.',
      'console-duo-wins_INFO': 'Duo wins overall. Played on console.',
      'console-duo-top3_INFO': 'Duo top3 overall. Played on console.',
      'console-duo-kills_INFO': 'Duo kills overall. Played on console.',
      'console-duo-per-min-kills_INFO': 'Duo kills per minute overall. Played on console.',
      'console-duo-per-match-kills_INFO': 'Duo kills per match overall. Played on console.',
      'console-duo-winrate_INFO': 'Duo winrate overall. Played on console.',
      'console-squad-games_INFO': 'Number of played squad games overall. Played on console.',
      'console-squad-wins_INFO': 'Squad wins overall. Played on console.',
      'console-squad-top3_INFO': 'Suqad top3 overall. Played on console.',
      'console-squad-kills_INFO': 'Squad kills overall. Played on console.',
      'console-squad-per-min-kills_INFO': 'Squad kills per minute overall. Played on console.',
      'console-squad-per-match-kills_INFO': 'Squad kills per match overall. Played on console.',
      'console-squad-winrate_INFO': 'Sqaud winrate overall. Played on console.',
      'mobile-overall-games_INFO': 'Number of played games overall. Played on mobile.',
      'mobile-overall-wins_INFO': 'Wins overall. Played on mobile.',
      'mobile-overall-top3_INFO': 'Top3 overall. Played on mobile.',
      'mobile-overall-kills_INFO': 'Kills overall. Played on mobile.',
      'mobile-overall-per-min-kills_INFO': 'Kills per minute overall. Played on mobile.',
      'mobile-overall-per-match-kills_INFO': 'Kills per match overall. Played on mobile.',
      'mobile-overall-winrate_INFO': 'Winrate overall. Played on mobile.',
      'mobile-solo-games_INFO': 'Number of played solo games overall. Played on mobile.',
      'mobile-solo-wins_INFO': 'Solo wins overall. Played on mobile.',
      'mobile-solo-top3_INFO': 'Solo top3 overall. Played on mobile.',
      'mobile-solo-kills_INFO': 'Solo kills overall. Played on mobile.',
      'mobile-solo-per-min-kills_INFO': 'Solo kills per minute overall. Played on mobile.',
      'mobile-solo-per-match-kills_INFO': 'Solo kills per match overall. Played on mobile.',
      'mobile-solo-winrate_INFO': 'Solo winrate overall. Played on mobile.',
      'mobile-duo-games_INFO': 'Number of played duo games overall. Played on mobile.',
      'mobile-duo-wins_INFO': 'Duo wins overall. Played on mobile.',
      'mobile-duo-top3_INFO': 'Duo top3 overall. Played on mobile.',
      'mobile-duo-kills_INFO': 'Duo kills overall. Played on mobile.',
      'mobile-duo-per-min-kills_INFO': 'Duo kills per minute overall. Played on mobile.',
      'mobile-duo-per-match-kills_INFO': 'Duo kills per match overall. Played on mobile.',
      'mobile-duo-winrate_INFO': 'Duo winrate overall. Played on mobile.',
      'mobile-squad-games_INFO': 'Number of played squad games overall. Played on mobile.',
      'mobile-squad-wins_INFO': 'Squad wins overall. Played on mobile.',
      'mobile-squad-top3_INFO': 'Suqad top3 overall. Played on mobile.',
      'mobile-squad-kills_INFO': 'Squad kills overall. Played on mobile.',
      'mobile-squad-per-min-kills_INFO': 'Squad kills per minute overall. Played on mobile.',
      'mobile-squad-per-match-kills_INFO': 'Squad kills per match overall. Played on mobile.',
      'mobile-squad-winrate_INFO': 'Sqaud winrate overall. Played on mobile.',
    },
  },
  de: {
    translation: {
      LANDINGPAGE_PRE_HEADER: 'Spielkarriere Statistiken',
      LANDINGPAGE_SLOGAN: 'Deine Gaming-Karriere für Twitch Bots',
      LANDINGPAGE_SLOGAN_DESCRIPTION: 'Wir bieten dir Statistiken zu deiner Spielerkarriere und deinen Lieblingsspielen für deinen Twitchbot. Natürlich vollständig anpassbar.',
      CONTACT_US: 'KONTAKTIERE UNS',
      RIOT_GAMES: 'Riot Games',
      ACTIVISON_BLIZZARD: 'Activision Blizzard',
      EPIC_GAMES: 'Epic Games',
      WHY_XYZ_QUESTION: 'Und bevor Du fragst, warum unsere Webseite stats4u auf <strong>.xyz</strong> endet',
      WHY_XYZ_ANSWER: '✨ Für den Drip ✨',
      RIGHTS_RESERVED: 'Alle Rechte vorbehalten.',
      IMPRINT: 'Impressum',
      CONTACT: 'Kontakt',
      SUPPORT_SOON: 'Der Support-Service wird bald verfügbar sein.',
      TWITCH_GIF_ALT_TEXT: 'Chat-Bot Beispiel Animation',

      MISSING_API_INFO_NO_REPLACE: 'Wichtig: Wenn Du noch nicht genug Spiele im jeweiligen Spielmodus gespielt hast, werden die Textblöcke im Ergebnis nicht ersetzt.',

      // LOL TAGS
      'soloq-elo_DESC': 'SoloQ Elo',
      'soloq-wins_DESC': 'SoloQ Siege',
      'soloq-losses_DESC': 'SoloQ Niederlagen',
      'soloq-winrate_DESC': 'SoloQ Siegesrate',
      'soloq-games_DESC': 'SoloQ Spiele',
      'flexq-elo_DESC': 'FlexQ Elo',
      'flexq-wins_DESC': 'FlexQ Siege',
      'flexq-losses_DESC': 'FlexQ Niederlagen',
      'flexq-winrate_DESC': 'FlexQ Siegesrate',
      'flexq-games_DESC': 'FlexQ Spiele',
      'summoner-name_DESC': 'Spielername',

      // ACTIVISION
      PROFILE_ID: 'Profil ID',
      REGION: 'Region',
      STARCRAFT2: 'StarCraft II',

      // LOL
      SUMMONER_NAME: 'Spielername',
      BOT_SOFTWARE: 'Bot software',
      LOL: 'League of Legends',
      TFT: 'Teamfight Tactics',
      TEXT_BLOCKS: 'Textblöcke',
      TEXT_BLOCKS_INFO_1: 'Benutze die Textblöcke um Deine Chat-Nachricht zu bauen. Die Chat-Nachricht wird befüllt mit',
      TEXT_BLOCKS_INFO_2: 'Beispieldaten',
      TEXT_BLOCKS_INFO_3: 'Um das Ergebnis für Dein Konto zu überprüfen klicke auf "Erstellen" und füge den Wert vom "Ergebnis"-Feld in dein Bot Kommando ein oder klicke auf "Prüfen".',

      GENERATE: 'Erstellen',
      OUTPUT: 'Chatnachricht',

      GENERATED_CONTENT_INFO: 'Du kannst hier zusätzlichen Text eintragen, um die Ausgabe nach einen Wünschen azupassen. ACHTUNG: Die Variablen dürfen dabei nicht verändert werden.',
      OUTPUT_INFO_1: 'So würde es in Deinem Twitch-Chat mit Beispieldaten aussehen, mit',
      OUTPUT_INFO_2: 'Beispieldaten',
      RESULT: 'Ergebnis',
      RESULT_INFO: 'Füge dieses Ergebnis als Wert für Deinen Bot-Befehl ein.',
      RESULT_COPY: 'Kopieren',
      RESULT_CHECK: 'Prüfen',

      CLIPBOARD_COPIED: 'Kopiert',
      CLIPBOARD_COPIED_INFO: 'Der Wert wurde kopiert.',

      // LOL TAGS
      SOLOQ_ELO_DESC: 'SoloQ Elo',
      SOLOQ_WINS_DESC: 'SoloQ Siege',
      SOLOQ_LOSSES_DESC: 'SoloQ Niederlagen',
      SOLOQ_WINRATE_DESC: 'SoloQ Gewinnrate',
      SOLOQ_GAMES_DESC: 'SoloQ Spiele',
      FLEXQ_ELO_DESC: 'FlexQ Elo',
      FLEXQ_WINS_DESC: 'FlexQ Siege',
      FLEXQ_LOSSES_DESC: 'FlexQ Niederlagen',
      FLEXQ_WINRATE_DESC: 'FlexQ Gewinnrate',
      FLEXQ_GAMES_DESC: 'FlexQ Spiele',
      SUMMONER_NAME_DESC: 'Spielername',

      SOLOQ_ELO_TOOLTIP: 'Deine SoloQ Elo',
      SOLOQ_WINS_TOOLTIP: 'Anzahl an Siege in SoloQ',
      SOLOQ_LOSSES_TOOLTIP: 'Anzahl an Niederlagen in SoloQ',
      SOLOQ_WINRATE_TOOLTIP: 'Gewinnrate (Prozent) über alle SoloQ Spiele',
      SOLOQ_GAMES_TOOLTIP: 'Anzahl an Spiele in SoloQ',
      FLEXQ_ELO_TOOLTIP: 'Deine FlexQ Elo',
      FLEXQ_WINS_TOOLTIP: 'Anzahl an Siege in FlexQ',
      FLEXQ_LOSSES_TOOLTIP: 'Anzahl an Niederlagen in FlexQ',
      FLEXQ_WINRATE_TOOLTIP: 'Gewinnrate (Prozent) über alle FlexQ Spiele',
      FLEXQ_GAMES_TOOLTIP: 'Anzahl an Spiele in FlexQ',
      SUMMONER_NAME_TOOLTIP: 'Spielername',


      // TFT
      ELO_DESC: 'Elo',
      WINS_DESC: 'Siege',
      LOSSES_DESC: 'Niederlagen',
      WINRATE_DESC: 'Gewinnrate',
      GAMES_DESC: 'Spiele',
      ELO_INFO: 'Deine TFT Elo',
      WINS_INFO: 'Spiele gewonnen',
      LOSSES_INFO: 'Spiele verloren',
      WINRATE_INFO: 'Gewinnrate prozentual',
      GAMES_INFO: 'Anzahl an gespielten Runden',

      // SC2
      '1v1-rank_DESC': '1v1 Rank',
      '1v1-winrate_DESC': '1v1 Gewinnrate',
      '1v1-games_DESC': '1v1 Spiele',
      '1v1-wins_DESC': '1v1 Siege',
      'profile-name_DESC': 'Spielername',
      'clan-name_DESC': 'Clanname',

      '1V1_RANK_INFO': 'Your rank',
      '1V1_WINRATE_INFO': 'Deines Siegesrate in Prozent',
      '1V1_GAMES_INFO': 'Anzahl an gespielten 1vs1',
      '1V1_WINS_INFO': 'Anzahl an gewonnen 1vs1',
      'PROFILE_NAME_INFO': 'Dein Name in SC2',
      'CLAN_NAME_INFO': 'Name Deines SC2 Clans',

      // Overwatch
      'rating_DESC': 'Bewertung',
      'prestige_DESC': 'Prestige',
      'level_DESC': 'Level',
      'games_DESC': 'Spiele',
      'won_DESC': 'Gewonnen',
      'medals-bronze_DESC': 'Bronzemedallien',
      'medals-silver_DESC': 'Silvermedallien',
      'medals-gold_DESC': 'Goldmedallien',
      'medals_DESC': 'Medallien',

      'rating_INFO': 'Bewertung',
      'prestige_INFO': 'Prestige',
      'level_INFO': 'Level',
      'games_INFO': 'Anzahl der gespielten Spiele',
      'won_INFO': 'Anzahl der gewonnenen Spiele',
      'medals-bronze_INFO': 'Anzahl der gesammelten Bronzemedallien',
      'medals-silver_INFO': 'Anzahl der gesammelten Silbermedallien',
      'medals-gold_INFO': 'Anzahl der gesammelten Goldmedallien',
      'medals_INFO': 'Anzahl der gesammelten Medallien',

      'BATTLE_TAG': 'Battle tag',

      // FORTNITE
      'All': 'All',
      'Computer': 'Computer',
      'Console': 'Konsolen',
      'Mobile': 'Mobil',

      'Overall': 'Insgesamt',
      'Solo': 'Alleine',
      'Duo': 'Zweier Teams',
      'Squad': 'Vierer Teams',
      'PLAYERS_NAME': 'Spielername',

      // FORTNITE tags
      'all-overall-games_DESC': 'Spiele',
      'all-overall-wins_DESC': 'Siege',
      'all-overall-top3_DESC': 'Top3',
      'all-overall-kills_DESC': 'Kills',
      'all-overall-per-min-kills_DESC': 'Kills pro Minute',
      'all-overall-per-match-kills_DESC': 'Kills pro Spiel',
      'all-overall-winrate_DESC': 'Winrate',
      'all-solo-games_DESC': 'Spiele',
      'all-solo-wins_DESC': 'Siege',
      'all-solo-top3_DESC': 'Top3',
      'all-solo-kills_DESC': 'Kills',
      'all-solo-per-min-kills_DESC': 'Kills pro Minute',
      'all-solo-per-match-kills_DESC': 'Kills pro Spiel',
      'all-solo-winrate_DESC': 'Winrate',
      'all-duo-games_DESC': 'Spiele',
      'all-duo-wins_DESC': 'Siege',
      'all-duo-top3_DESC': 'Top3',
      'all-duo-kills_DESC': 'Kills',
      'all-duo-per-min-kills_DESC': 'Kills pro Minute',
      'all-duo-per-match-kills_DESC': 'Kills pro Spiel',
      'all-duo-winrate_DESC': 'Winrate',
      'all-squad-games_DESC': 'Spiele',
      'all-squad-wins_DESC': 'Siege',
      'all-squad-top3_DESC': 'Top3',
      'all-squad-kills_DESC': 'Kills',
      'all-squad-per-min-kills_DESC': 'Kills pro Minute',
      'all-squad-per-match-kills_DESC': 'Kills pro Spiel',
      'all-squad-winrate_DESC': 'Winrate',
      'computer-overall-games_DESC': 'Spiele',
      'computer-overall-wins_DESC': 'Siege',
      'computer-overall-top3_DESC': 'Top3',
      'computer-overall-kills_DESC': 'Kills',
      'computer-overall-per-min-kills_DESC': 'Kills pro Minute',
      'computer-overall-per-match-kills_DESC': 'Kills pro Spiel',
      'computer-overall-winrate_DESC': 'Winrate',
      'computer-solo-games_DESC': 'Spiele',
      'computer-solo-wins_DESC': 'Siege',
      'computer-solo-top3_DESC': 'Top3',
      'computer-solo-kills_DESC': 'Kills',
      'computer-solo-per-min-kills_DESC': 'Kills pro Minute',
      'computer-solo-per-match-kills_DESC': 'Kills pro Spiel',
      'computer-solo-winrate_DESC': 'Winrate',
      'computer-duo-games_DESC': 'Spiele',
      'computer-duo-wins_DESC': 'Siege',
      'computer-duo-top3_DESC': 'Top3',
      'computer-duo-kills_DESC': 'Kills',
      'computer-duo-per-min-kills_DESC': 'Kills pro Minute',
      'computer-duo-per-match-kills_DESC': 'Kills pro Spiel',
      'computer-duo-winrate_DESC': 'Winrate',
      'computer-squad-games_DESC': 'Spiele',
      'computer-squad-wins_DESC': 'Siege',
      'computer-squad-top3_DESC': 'Top3',
      'computer-squad-kills_DESC': 'Kills',
      'computer-squad-per-min-kills_DESC': 'Kills pro Minute',
      'computer-squad-per-match-kills_DESC': 'Kills pro Spiel',
      'computer-squad-winrate_DESC': 'Winrate',
      'console-overall-games_DESC': 'Spiele',
      'console-overall-wins_DESC': 'Siege',
      'console-overall-top3_DESC': 'Top3',
      'console-overall-kills_DESC': 'Kills',
      'console-overall-per-min-kills_DESC': 'Kills pro Minute',
      'console-overall-per-match-kills_DESC': 'Kills pro Spiel',
      'console-overall-winrate_DESC': 'Winrate',
      'console-solo-games_DESC': 'Spiele',
      'console-solo-wins_DESC': 'Siege',
      'console-solo-top3_DESC': 'Top3',
      'console-solo-kills_DESC': 'Kills',
      'console-solo-per-min-kills_DESC': 'Kills pro Minute',
      'console-solo-per-match-kills_DESC': 'Kills pro Spiel',
      'console-solo-winrate_DESC': 'Winrate',
      'console-duo-games_DESC': 'Spiele',
      'console-duo-wins_DESC': 'Siege',
      'console-duo-top3_DESC': 'Top3',
      'console-duo-kills_DESC': 'Kills',
      'console-duo-per-min-kills_DESC': 'Kills pro Minute',
      'console-duo-per-match-kills_DESC': 'Kills pro Spiel',
      'console-duo-winrate_DESC': 'Winrate',
      'console-squad-games_DESC': 'Spiele',
      'console-squad-wins_DESC': 'Siege',
      'console-squad-top3_DESC': 'Top3',
      'console-squad-kills_DESC': 'Kills',
      'console-squad-per-min-kills_DESC': 'Kills pro Minute',
      'console-squad-per-match-kills_DESC': 'Kills pro Spiel',
      'console-squad-winrate_DESC': 'Winrate',
      'mobile-overall-games_DESC': 'Spiele',
      'mobile-overall-wins_DESC': 'Siege',
      'mobile-overall-top3_DESC': 'Top3',
      'mobile-overall-kills_DESC': 'Kills',
      'mobile-overall-per-min-kills_DESC': 'Kills pro Minute',
      'mobile-overall-per-match-kills_DESC': 'Kills pro Spiel',
      'mobile-overall-winrate_DESC': 'Winrate',
      'mobile-solo-games_DESC': 'Spiele',
      'mobile-solo-wins_DESC': 'Siege',
      'mobile-solo-top3_DESC': 'Top3',
      'mobile-solo-kills_DESC': 'Kills',
      'mobile-solo-per-min-kills_DESC': 'Kills pro Minute',
      'mobile-solo-per-match-kills_DESC': 'Kills pro Spiel',
      'mobile-solo-winrate_DESC': 'Winrate',
      'mobile-duo-games_DESC': 'Spiele',
      'mobile-duo-wins_DESC': 'Siege',
      'mobile-duo-top3_DESC': 'Top3',
      'mobile-duo-kills_DESC': 'Kills',
      'mobile-duo-per-min-kills_DESC': 'Kills pro Minute',
      'mobile-duo-per-match-kills_DESC': 'Kills pro Spiel',
      'mobile-duo-winrate_DESC': 'Winrate',
      'mobile-squad-games_DESC': 'Spiele',
      'mobile-squad-wins_DESC': 'Siege',
      'mobile-squad-top3_DESC': 'Top3',
      'mobile-squad-kills_DESC': 'Kills',
      'mobile-squad-per-min-kills_DESC': 'Kills pro Minute',
      'mobile-squad-per-match-kills_DESC': 'Kills pro Spiel',
      'mobile-squad-winrate_DESC': 'Winrate',


    },
  },
}

//i18N Initialization
i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: "en", //default language
    keySeparator: false,
    transSupportBasicHtmlNodes: true,
    // interpolation: {
    //   escapeValue: false,
    // },
    transKeepBasicHtmlNodesFor: ['br'],
    // react-i18next options
    react: {
      wait: true
    }
  })

export default i18n