import { RefObject } from "react"

export const botSelect = [
  { key: 'streamlabs_chatbot', translate: 'Streamlabs Chatbot', syntax: (url: string) => `$readapi(${url})` },
  { key: 'streamlabs_cloubot', translate: 'Streamlabs Cloudbot', syntax: (url: string) => `{readapi.${url}}` },
  { key: 'nightbot', translate: 'Nightbot', syntax: (url: string) => `$(urlfetch ${url})` },
]

export const regionSelectSC2 = [
  { key: '1', translate: 'US' },
  { key: '2', translate: 'EU' },
  { key: '3', translate: 'KO/TW' },
  { key: '4', translate: 'CN' },
]
export const regionSelectOverwatch = [
  { key: 'us', translate: 'US' },
  { key: 'eu', translate: 'EU' },
  { key: 'asia', translate: 'ASIA' },
]

export const platformSelectOverwatch = [
  { key: 'pc', translate: 'Computer' },
  { key: 'psn', translate: 'Playstation' },
  { key: 'xbl', translate: 'XBox' },
]

export const leagueOfLegendsTags = [
  { key: 'soloq-elo', example: 'PLATINUM III 20LP' },
  { key: 'soloq-wins', example: '66' },
  { key: 'soloq-losses', example: '71' },
  { key: 'soloq-winrate', example: '54%' },
  { key: 'soloq-games', example: '106' },
  { key: 'flexq-elo', example: 'GOLD II 51LP' },
  { key: 'flexq-wins', example: '46' },
  { key: 'flexq-losses', example: '71' },
  { key: 'flexq-winrate', example: '51%' },
  { key: 'flexq-games', example: '121' },
  { key: 'summoner-name', example: 'Mr TrustMe' },
]

export const teamfightTacticsTags = [
  { key: 'tft-elo', example: 'PLATINUM III 20LP' },
  { key: 'tft-wins', example: '66' },
  { key: 'tft-losses', example: '71' },
  { key: 'tft-winrate', example: '54%' },
  { key: 'tft-games', example: '106' },
  { key: 'summoner-name', example: 'Mr TrustMe' },
]

export const startCraft2Tags = [
  { key: '1v1-rank', example: 'Rank 3 GRANDMASTER' },
  { key: '1v1-winrate', example: '66%' },
  { key: '1v1-games', example: '86' },
  { key: '1v1-wins', example: '66' },
  { key: 'profile-name', example: 'Aqua Woman' },
  { key: 'clan-name', example: 'sc2ForLife' },
]

export const fortniteTags = [
  { key: 'all-overall-games', example: '1951' },
  { key: 'all-overall-wins', example: '323' },
  { key: 'all-overall-top3', example: '490' },
  { key: 'all-overall-kills', example: '870' },
  { key: 'all-overall-per-min-kills', example: '0.132' },
  { key: 'all-overall-per-match-kills', example: '5.74' },
  { key: 'all-overall-winrate', example: '36%' },
  { key: 'all-solo-games', example: '1951' },
  { key: 'all-solo-wins', example: '323' },
  { key: 'all-solo-top3', example: '490' },
  { key: 'all-solo-kills', example: '870' },
  { key: 'all-solo-per-min-kills', example: '0.132' },
  { key: 'all-solo-per-match-kills', example: '5.74' },
  { key: 'all-solo-winrate', example: '36%' },
  { key: 'all-duo-games', example: '1951' },
  { key: 'all-duo-wins', example: '323' },
  { key: 'all-duo-top3', example: '490' },
  { key: 'all-duo-kills', example: '870' },
  { key: 'all-duo-per-min-kills', example: '0.132' },
  { key: 'all-duo-per-match-kills', example: '5.74' },
  { key: 'all-duo-winrate', example: '36%' },
  { key: 'all-squad-games', example: '1951' },
  { key: 'all-squad-wins', example: '323' },
  { key: 'all-squad-top3', example: '490' },
  { key: 'all-squad-kills', example: '870' },
  { key: 'all-squad-per-min-kills', example: '0.132' },
  { key: 'all-squad-per-match-kills', example: '5.74' },
  { key: 'all-squad-winrate', example: '36%' },
  { key: 'computer-overall-games', example: '1951' },
  { key: 'computer-overall-wins', example: '323' },
  { key: 'computer-overall-top3', example: '490' },
  { key: 'computer-overall-kills', example: '870' },
  { key: 'computer-overall-per-min-kills', example: '0.132' },
  { key: 'computer-overall-per-match-kills', example: '5.74' },
  { key: 'computer-overall-winrate', example: '36%' },
  { key: 'computer-solo-games', example: '1951' },
  { key: 'computer-solo-wins', example: '323' },
  { key: 'computer-solo-top3', example: '490' },
  { key: 'computer-solo-kills', example: '870' },
  { key: 'computer-solo-per-min-kills', example: '0.132' },
  { key: 'computer-solo-per-match-kills', example: '5.74' },
  { key: 'computer-solo-winrate', example: '36%' },
  { key: 'computer-duo-games', example: '1951' },
  { key: 'computer-duo-wins', example: '323' },
  { key: 'computer-duo-top3', example: '490' },
  { key: 'computer-duo-kills', example: '870' },
  { key: 'computer-duo-per-min-kills', example: '0.132' },
  { key: 'computer-duo-per-match-kills', example: '5.74' },
  { key: 'computer-duo-winrate', example: '36%' },
  { key: 'computer-squad-games', example: '1951' },
  { key: 'computer-squad-wins', example: '323' },
  { key: 'computer-squad-top3', example: '490' },
  { key: 'computer-squad-kills', example: '870' },
  { key: 'computer-squad-per-min-kills', example: '0.132' },
  { key: 'computer-squad-per-match-kills', example: '5.74' },
  { key: 'computer-squad-winrate', example: '36%' },
  { key: 'console-overall-games', example: '1951' },
  { key: 'console-overall-wins', example: '323' },
  { key: 'console-overall-top3', example: '490' },
  { key: 'console-overall-kills', example: '870' },
  { key: 'console-overall-per-min-kills', example: '0.132' },
  { key: 'console-overall-per-match-kills', example: '5.74' },
  { key: 'console-overall-winrate', example: '36%' },
  { key: 'console-solo-games', example: '1951' },
  { key: 'console-solo-wins', example: '323' },
  { key: 'console-solo-top3', example: '490' },
  { key: 'console-solo-kills', example: '870' },
  { key: 'console-solo-per-min-kills', example: '0.132' },
  { key: 'console-solo-per-match-kills', example: '5.74' },
  { key: 'console-solo-winrate', example: '36%' },
  { key: 'console-duo-games', example: '1951' },
  { key: 'console-duo-wins', example: '323' },
  { key: 'console-duo-top3', example: '490' },
  { key: 'console-duo-kills', example: '870' },
  { key: 'console-duo-per-min-kills', example: '0.132' },
  { key: 'console-duo-per-match-kills', example: '5.74' },
  { key: 'console-duo-winrate', example: '36%' },
  { key: 'console-squad-games', example: '1951' },
  { key: 'console-squad-wins', example: '323' },
  { key: 'console-squad-top3', example: '490' },
  { key: 'console-squad-kills', example: '870' },
  { key: 'console-squad-per-min-kills', example: '0.132' },
  { key: 'console-squad-per-match-kills', example: '5.74' },
  { key: 'console-squad-winrate', example: '36%' },
  { key: 'mobile-overall-games', example: '1951' },
  { key: 'mobile-overall-wins', example: '323' },
  { key: 'mobile-overall-top3', example: '490' },
  { key: 'mobile-overall-kills', example: '870' },
  { key: 'mobile-overall-per-min-kills', example: '0.132' },
  { key: 'mobile-overall-per-match-kills', example: '5.74' },
  { key: 'mobile-overall-winrate', example: '36%' },
  { key: 'mobile-solo-games', example: '1951' },
  { key: 'mobile-solo-wins', example: '323' },
  { key: 'mobile-solo-top3', example: '490' },
  { key: 'mobile-solo-kills', example: '870' },
  { key: 'mobile-solo-per-min-kills', example: '0.132' },
  { key: 'mobile-solo-per-match-kills', example: '5.74' },
  { key: 'mobile-solo-winrate', example: '36%' },
  { key: 'mobile-duo-games', example: '1951' },
  { key: 'mobile-duo-wins', example: '323' },
  { key: 'mobile-duo-top3', example: '490' },
  { key: 'mobile-duo-kills', example: '870' },
  { key: 'mobile-duo-per-min-kills', example: '0.132' },
  { key: 'mobile-duo-per-match-kills', example: '5.74' },
  { key: 'mobile-duo-winrate', example: '36%' },
  { key: 'mobile-squad-games', example: '1951' },
  { key: 'mobile-squad-wins', example: '323' },
  { key: 'mobile-squad-top3', example: '490' },
  { key: 'mobile-squad-kills', example: '870' },
  { key: 'mobile-squad-per-min-kills', example: '0.132' },
  { key: 'mobile-squad-per-match-kills', example: '5.74' },
  { key: 'mobile-squad-winrate', example: '36%' },
]

export const overwatchTags = [
  { key: 'rating', example: '4701' },
  { key: 'prestige', example: '12' },
  { key: 'level', example: '3' },
  { key: 'games', example: '97' },
  { key: 'won', example: '60' },
  { key: 'medals-bronze', example: '154' },
  { key: 'medals-silver', example: '44' },
  { key: 'medals-gold', example: '55' },
  { key: 'medals', example: '66' },
]

export const insertTag = (content: string, key: string, input: RefObject<HTMLInputElement>) => {
  const cursorPosition = input?.current?.selectionStart
  const charBeforeCursor = content.substring(cursorPosition! - 1, cursorPosition!)
  const textBeforeCursorPosition = content.substring(0, cursorPosition!)
  const textAfterCursorPosition = content.substring(cursorPosition!, content.length)
  
  let keyToUse
  if (charBeforeCursor === ' ' || content.length === 0) {
    keyToUse = `$${key}`
  }
  else {
    keyToUse = ` $${key}`
  }
  
  return textBeforeCursorPosition + keyToUse +  textAfterCursorPosition
}

export const focusInput = (ref: RefObject<HTMLInputElement>) => {
  ref?.current?.focus()
}