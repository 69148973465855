import React from 'react'
import { Box, Text, Input, Button, useToast } from '@chakra-ui/react'
import * as Styles from 'styles/general'
import { useTranslation } from 'react-i18next'

export const ConfigurationFooter = ({ urlResult, urlTest }: { urlResult: string, urlTest: string }) => {
  const toast = useToast()
  const { t } = useTranslation()

  const copyToClipboard = (urlResult: string) => {
    navigator.clipboard.writeText(urlResult)
    return toast({
      title: t('CLIPBOARD_COPIED'),
      description: t('CLIPBOARD_COPIED_INFO'),
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
  }
  return (
    <>
      <Box display={'flex'} mt={4}>
        <Box width={'100%'}>
          <Text mb={1}>{t('RESULT')}</Text>
          <Input value={urlResult} />
        </Box>
        <Button mt={'auto'} ml={4} disabled={urlResult === ''} title={t('RESULT_COPY_TITLE')} onClick={() => copyToClipboard(urlResult)}>{t('RESULT_COPY')}</Button>
        <a className={Styles.flex} target={'_blank'} rel="noreferrer" href={urlTest}>
          <Button mt={'auto'} ml={4} disabled={urlResult === ''} title={t('RESULT_CHECK_TITLE')}>
            {t('RESULT_CHECK')}
          </Button>
        </a>
      </Box>
      <Text fontSize={'xs'}>{t('RESULT_INFO')}</Text>
    </>
  )
}