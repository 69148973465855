import React, { useState, useMemo, useEffect, createRef, RefObject } from 'react'
import { Box, Input, Text, FormLabel, FormControl, Select, Tag, RadioGroup, Radio, Stack, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { TwitchChatPreview } from 'components/TwitchChatPreview'
import { botSelect, fortniteTags } from 'utils/Utils'

import * as Styles from 'styles/general'
import * as Config from 'config/config'
import { useTranslation } from 'react-i18next'
import { ConfigurationFooter } from './ConfigurationFooter'
import { Generator } from './Generator'
import { insertTag, focusInput } from 'utils/Utils'
interface ITag {
  key: string,
  example: string
}

export const EpicGames = () => {
  const { t } = useTranslation()
  const [game, setGame] = useState('fortnite')
  const [tags, setTags] = useState(fortniteTags)
  const [name, setName] = useState('')
  const [botDropdown, setBotDropdown] = useState(botSelect[0].key)
  const [category, setCategory] = useState('overall')
  const [buildingBlockContent, setBuildingBlockContent] = useState('')
  const [urlResult, setUrlResult] = useState('')
  const [urlTest, setURLTest] = useState('')
  const input = createRef<HTMLInputElement>()
  const inputGenerator = createRef<HTMLInputElement>()

  const generate = () => {
    if (name !== '') {
      const bot = botSelect.find(bot => bot.key === botDropdown)
      const url = `${Config.serverUrl}?game=${game}&name=${encodeURIComponent(name)}&content=${encodeURIComponent(buildingBlockContent)}`
      setURLTest(url)
      setUrlResult(bot!.syntax(url))
    }
    else {
      focusInput(input)
    }
  }

  useEffect(() => {
    setTags({ 'fortnite': fortniteTags }[game]!)
    setName('')
    setBotDropdown(botSelect[0].key)
    setUrlResult('')
    setBuildingBlockContent('')
    setCategory('overall')
  }, [game])

  useEffect(() => {
    focusInput(input)
    // eslint-disable-next-line
  }, [])

  const getExampleText = useMemo(() => {
    let temporary = buildingBlockContent
    tags.forEach(({ key, example }) => {
      temporary = temporary.replaceAll('$' + key, example)
    })
    return (
      <TwitchChatPreview text={temporary} />
    )
  }, [buildingBlockContent, tags])

  return (
    <div className={Styles.container}>
      <Box width={[400, 500, 600]} display={'flex'} flexDirection={'column'} p={'2rem'} backgroundColor={'white'} borderRadius={'1rem'}>
        <Box display={'flex'} mb={4}>
          <Box>
            <Text mb={1}>{t('PLAYERS_NAME')}</Text>
            <Input
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder={'Jochen'}
              width={'auto'}
              ref={input}
            />
          </Box>
          <FormControl ml={4}>
            <FormLabel mb={1} htmlFor='country'>Bot software</FormLabel>
            <Select id='information' value={botDropdown} onChange={e => setBotDropdown(e.target.value)} cursor={'pointer'}>
              {
                botSelect.map(item => <option value={item.key}>{item.translate}</option>)
              }
            </Select>
          </FormControl>
        </Box>
        <RadioGroup onChange={val => setGame(val)} value={game} mb={4}>
          <Stack direction='row'>
            <Radio value='fortnite'>Fortnite</Radio>
          </Stack>
        </RadioGroup>
        <Box display={'flex'} mb={4}>
          <FormControl>
            <FormLabel mb={0} htmlFor='country'>{t('TEXT_BLOCKS')}</FormLabel>
            <Text mb={2} fontSize={'xs'}>{t('TEXT_BLOCKS_INFO_1')} <span style={{ color: 'red' }}>{t('TEXT_BLOCKS_INFO_2')}</span>. {t('TEXT_BLOCKS_INFO_3')}</Text>
            <Text mb={2} fontSize={'xs'} color={'red'}>{t('MISSING_API_INFO_NO_REPLACE')}</Text>
            <Tabs onChange={() => setCategory('overall')}>
              <TabList className={Styles.tabs}>
                <Tab>{t('All')}</Tab>
                <Tab>{t('Computer')}</Tab>
                <Tab>{t('Console')}</Tab>
                <Tab>{t('Mobile')}</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <TabContent
                    category={category}
                    setCategory={setCategory}
                    tags={tags.filter(tag => tag.key.startsWith('all-') && tag.key.includes(`-${category}-`))}
                    buildingBlockContent={buildingBlockContent}
                    setBuildingBlockContent={setBuildingBlockContent}
                    inputRef={inputGenerator}
                  />
                </TabPanel>
                <TabPanel>
                  <TabContent
                    category={category}
                    setCategory={setCategory}
                    tags={tags.filter(tag => tag.key.startsWith('computer-') && tag.key.includes(`-${category}-`))}
                    buildingBlockContent={buildingBlockContent}
                    setBuildingBlockContent={setBuildingBlockContent}
                    inputRef={inputGenerator}
                  />
                </TabPanel>
                <TabPanel>
                  <TabContent
                    category={category}
                    setCategory={setCategory}
                    tags={tags.filter(tag => tag.key.startsWith('console-') && tag.key.includes(`-${category}-`))}
                    buildingBlockContent={buildingBlockContent}
                    setBuildingBlockContent={setBuildingBlockContent}
                    inputRef={inputGenerator}
                  />
                </TabPanel>
                <TabPanel>
                  <TabContent
                    category={category}
                    setCategory={setCategory}
                    tags={tags.filter(tag => tag.key.startsWith('mobile-') && tag.key.includes(`-${category}-`))}
                    buildingBlockContent={buildingBlockContent}
                    setBuildingBlockContent={setBuildingBlockContent}
                    inputRef={inputGenerator}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
            <Generator content={buildingBlockContent} setContent={setBuildingBlockContent} generate={generate} inputRef={inputGenerator}/>
            <Text>{t('OUTPUT')}</Text>
            <Text mb={2} fontSize={'xs'}>{t('OUTPUT_INFO_1')} <span style={{ color: 'red' }}>{t('OUTPUT_INFO_2')}</span>.</Text>
            <Text>{getExampleText}</Text>
          </FormControl>
        </Box>
        <ConfigurationFooter urlResult={urlResult} urlTest={urlTest}/>
      </Box>
    </div>
  )
}

const TabContent = ({ category, setCategory, tags, buildingBlockContent, setBuildingBlockContent, inputRef }: { category: string, setCategory: Function, tags: ITag[], buildingBlockContent: string, setBuildingBlockContent: Function, inputRef: RefObject<HTMLInputElement> }) => {
  const { t } = useTranslation()
  return (
    <>
      <RadioGroup onChange={val => setCategory(val)} value={category} mb={4}>
        <Stack direction='row'>
          <Radio value='overall'>{t('Overall')}</Radio>
          <Radio value='solo'>{t('Solo')}</Radio>
          <Radio value='duo'>{t('Duo')}</Radio>
          <Radio value='squad'>{t('Squad')}</Radio>
        </Stack>
      </RadioGroup>
      {
        tags.map(({ key }) => {
          return <Tag
            key={key}
            m={1}
            userSelect={'none'}
            cursor={'pointer'}
            onClick={() => setBuildingBlockContent(insertTag(buildingBlockContent, key, inputRef))}
            title={t(key + '_INFO')}>
            {t(key + '_DESC')}
          </Tag>
        })
      }
    </>
  )
}