import { Link } from "react-router-dom"
import { Box, Flex, Text } from '@chakra-ui/react'
import * as Styles from 'styles/general'
import { useTranslation } from 'react-i18next'

export const Footer = () => {

  const {t} = useTranslation()

  const randomNameOrder = ['Pascal Dittmann', 'Kevin Hubert'].sort((a, b) => 0.5 - Math.random()).join(' & ')

  return (
    <Box position='absolute' bottom='0px' w='100%'>
      <Flex justifyContent={'center'} alignItems={'center'} height={'max-content'} mt={'auto'} padding={2} minHeight={'40px'} flex-flexShrink={0}>
        <Flex flexDir={['column', 'row']} width={'100%'} flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-between'}>
          <Link to={'/imprint'} className={Styles.footerLink}>{t('IMPRINT')}</Link>
          <Text fontSize={['xs', 'md', 'md', 'md']} color='#ccc'>{`© 2022 ${randomNameOrder}. ${t('RIGHTS_RESERVED')}`}</Text>
        </Flex>
      </Flex>
    </Box>
  )
}