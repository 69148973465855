import React from 'react'
import { Box, Image, Text, Stack, Button, LinkBox, LinkOverlay, Link } from '@chakra-ui/react'
import * as Styles from 'styles/general'
import { card } from 'styles/card'
import * as ReactRouterDom from 'react-router-dom'
import RiotGamesLogo from 'resources/riotgames_logo.png'
import ActivisionLogo from 'resources/activision_logo.png'
import EpicGamesLogo from 'resources/epic_games_logo.png'
import stone from 'resources/stone.jpg'
import TwitchChatGif from 'resources/Demo.gif'
import { ParticleImage, particleThemeType } from './ParticleImage'

import { useTranslation, Trans } from 'react-i18next'

interface selectCardDef {
  label: string,
  link: string,
  logo: any,
  logoAlt: string,
  particleTheme: particleThemeType,
}

const SelectCard = ({ label, link, logo, logoAlt, particleTheme }: selectCardDef) => {
  return (
    <LinkBox className={card} height={[300, 300, 300]} width={['80%', '70%', '60%', '25%', '15%']}
      display='flex'
      alignItems='center'
      justifyContent='center'
      backgroundImage={stone}
      _hover={{
        boxShadow: "0px 0px 15px 3px #DB7291",
        transition: '.3s all',
      }}
      margin={'1rem !important'} // Something from Chakra uses margin here and override everything if !important is not used.
      userSelect='none'
    >
      <ParticleImage
        imageSrc={logo}
        imageAlt={logoAlt}
        particleTheme={particleTheme}
      />
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} >
        <Text mb={5} color='#eee' fontSize={['xl', '2xl', '2xl']} textAlign={'center'} fontWeight={'bold'}>{label}</Text>
      </Box>
      <LinkOverlay as={ReactRouterDom.Link} to={link} />
    </LinkBox>
  )
}

export const Selection = () => {
  const { t } = useTranslation()
  return (
    <>
      <Stack display='flex' flexDir={['column', 'column', 'column', 'row']} justifyContent='center' alignItems='center'>
        <SelectCard
          label={t('RIOT_GAMES')}
          logo={RiotGamesLogo}
          logoAlt={t('RIOT_GAMES') + ' Logo'}
          link={'/riotgames'}
          particleTheme={particleThemeType.fire}
        />
        <SelectCard
          label={t('ACTIVISON_BLIZZARD')}
          logo={ActivisionLogo}
          logoAlt={t('ACTIVISON_BLIZZARD') + ' Logo'}
          link={'/activision'}
          particleTheme={particleThemeType.water}
        />
        <SelectCard
          label={t('EPIC_GAMES')}
          logo={EpicGamesLogo}
          logoAlt={t('EPIC_GAMES') + ' Logo'}
          link={'/epicgames'}
          particleTheme={particleThemeType.nature}
        />
      </Stack>

      <Stack height={['min-content', 'min-content', 'min-content', '50vh']} 
       flexDir={['column', 'column', 'column', 'row']} mb={'5%'}>
        <Box flex={1} display='flex' alignItems='center' justifyContent='center'>
          <Box p={4} maxWidth={'600px'}>
            <Text as='h1' fontWeight={200} color='#bfbfff' fontSize='xl' textTransform='uppercase'>{t('LANDINGPAGE_PRE_HEADER')}</Text>
            <Text as='h2' fontWeight={200} color='white' lineHeight='110%' fontSize={['4xl', '6xl']}>{t('LANDINGPAGE_SLOGAN')}</Text>
            <Text as='h3' mt={4} fontWeight={200} color='#eee' fontSize='lg'>{t('LANDINGPAGE_SLOGAN_DESCRIPTION')}</Text>
            <Box display={'flex'} width={'100%'}>
              <Link href="mailto:padi.kehu.r2@gmail.com" marginLeft={['auto', 'auto', 'auto', 'unset']} marginRight={['auto', 'auto', 'auto', 'unset']} >
                <Button fontSize='lg' p={7} color='#fff' textTransform='uppercase' mt={4} className={Styles.contact_button}>{t('CONTACT_US')}</Button>
              </Link>
            </Box>
          </Box>
        </Box>
        <Box px={5} flex={1} display='flex' flexDir='column' alignItems='center' justifyContent='center' userSelect='none'>
          <Image alt={t('TWITCH_GIF_ALT_TEXT')} borderRadius={6} src={TwitchChatGif} />
        </Box>
      </Stack>

      <Box display='flex' flexDir='column' alignItems='center' justifyContent='center' p={'4rem'}>
        <Text data-i18n="[html]content.body" as='p' textAlign='center' fontWeight='200' fontSize='lg' color='#ddd'>
        <Trans i18nKey="WHY_XYZ_QUESTION" /> 
        </Text>
        <Text mb={4} as='p' fontSize='2xl' textAlign='center' color='#ddd'>
          {t('WHY_XYZ_ANSWER')}
        </Text>
      </Box>
    </>
  )
}