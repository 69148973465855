import * as Styles from 'styles/general'
import { Heading, Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'


export const Imprint = () => {

  const { t } = useTranslation()

  return (
    <Box className={Styles.white_font} paddingLeft={10}>
      <Heading fontSize={'4xl'} mb={4}>{t('IMPRINT')}</Heading>
      <Heading fontSize={'xl'} mb={2}>Pascal Dittmann</Heading>
      <Heading fontSize={'xl'} mb={2}>Karlsruher Straße 4</Heading>
      <Heading fontSize={'xl'} mb={8}>10711 Berlin</Heading>
      <Heading fontSize={'3xl'} mb={4}>{t('CONTACT')}</Heading>
      <Heading fontSize={'xl'}>padi.kehu.r2@gmail.com</Heading>
    </Box>
  )
}