import React, { RefObject } from 'react'
import { Box, Input, Text, Button, Stack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export const Generator = ({ content, setContent, generate, inputRef }: { content: string, setContent: (val: string) => void, generate: Function, inputRef: RefObject<HTMLInputElement> }) => {
  const { t } = useTranslation()
  return (
    <Box display={'flex'} mb={2}>
      <Stack width={'100%'}>
        <Text mt={2} mb={0} whiteSpace={'break-spaces'} fontSize={'xs'}>{t('GENERATED_CONTENT_INFO')}</Text>
        <Stack direction={'row'} display={'flex'}>
          <Input value={content} onChange={(e) => setContent(e.target.value)} ref={inputRef} />
          <Button mt={'auto'} ml={4} onClick={() => generate()}>{t('GENERATE')}</Button>
        </Stack>
      </Stack>
    </Box>
  )
}