import { css } from '@emotion/css'

export const card = css`
  display: flex;
  min-height: 0;
  flex-direction: column;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.55);
  -moz-box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.55);
  box-shadow: 0px 0px 40px 0px rgba(0,0,0,0.55);
  cursor: pointer;
  border-radius: 0.425rem;
  &:hover {
    -webkit-box-shadow: 0px 0px 40px 8px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 0px 40px 8px rgba(0,0,0,0.55);
    box-shadow: 0px 0px 40px 8px rgba(0,0,0,0.55);
  }
  margin: 2rem 1.5rem;
`