import React, { useState, useMemo, useEffect, createRef } from 'react'
import { Box, Input, Text, FormLabel, FormControl, Select, Tag, RadioGroup, Radio, Stack } from '@chakra-ui/react'
import { TwitchChatPreview } from 'components/TwitchChatPreview'
import { botSelect, regionSelectSC2, regionSelectOverwatch, platformSelectOverwatch, startCraft2Tags, overwatchTags } from 'utils/Utils'

import * as Styles from 'styles/general'
import * as Config from 'config/config'
import { insertTag, focusInput } from 'utils/Utils'
import { useTranslation } from 'react-i18next'
import { ConfigurationFooter } from './ConfigurationFooter'
import { Generator } from './Generator'

export const Activision = () => {
  const { t } = useTranslation()
  const [game, setGame] = useState('sc2')
  const [tags, setTags] = useState(startCraft2Tags)
  const [profileID, setProfileID] = useState('')
  const [botDropdown, setBotDropdown] = useState(botSelect[0].key)
  const [regionDropdown, setRegionDropdown] = useState(regionSelectSC2[0].key)
  const [platformSelect, setPlatformSelect] = useState(platformSelectOverwatch[0].key)
  const [buildingBlockContent, setBuildingBlockContent] = useState('')
  const [urlResult, setUrlResult] = useState('')
  const [urlTest, setURLTest] = useState('')
  const input = createRef<HTMLInputElement>()
  const inputGenerator = createRef<HTMLInputElement>()

  const generate = () => {
    if (profileID !== '') {
      const bot = botSelect.find(bot => bot.key === botDropdown)
      const queryParams = game === 'sc2'
        ? `&region=${regionDropdown}&profileid=${encodeURIComponent(profileID)}`
        : `&tag=${encodeURIComponent(profileID.replace('#', '-'))}&region=${regionDropdown}&platform=${platformSelect}`
      const url = `${Config.serverUrl}?game=${game}${queryParams}&content=${encodeURIComponent(buildingBlockContent)}`
      setURLTest(url)
      setUrlResult(bot!.syntax(url))
    }
    else {
      focusInput(input)
    }
  }

  useEffect(() => {
    setTags({ 'sc2': startCraft2Tags, 'overwatch': overwatchTags }[game]!)
    setProfileID('')
    setRegionDropdown({ 'sc2': regionSelectSC2[0].key, 'overwatch': regionSelectOverwatch[0].key }[game]!)
    setUrlResult('')
    setBuildingBlockContent('')
    setPlatformSelect(platformSelectOverwatch[0].key)
  }, [game])

  useEffect(() => {
    focusInput(input)
    // eslint-disable-next-line
  }, [])

  const getExampleText = useMemo(() => {
    let temporary = buildingBlockContent
    tags.forEach(({ key, example }) => {
      temporary = temporary.replaceAll('$' + key, example)
    })
    return (
      <TwitchChatPreview text={temporary} />
    )
  }, [buildingBlockContent, tags])

  const regionSelect = game === 'sc2' ? regionSelectSC2 : regionSelectOverwatch
  return (
    <div className={Styles.container}>
      <Box width={[400, 500, 600]} display={'flex'} flexDirection={'column'} p={'2rem'} backgroundColor='white' borderRadius='1rem'>
        <Box display={'flex'} mb={4}>
          <Box>
          <Text mb={1}>{t(game === 'sc2' ? 'PROFILE_ID' : 'BATTLE_TAG')}</Text>
            <Input
              value={profileID}
              onChange={e => setProfileID(e.target.value)}
              placeholder={
                game === 'sc2'
                ? '8652342'
                : platformSelect === 'pc'
                  ? 'Jochen#1234'
                  : 'Jochen'
              }
              width={'auto'}
              ref={input}
            />
          </Box>
          <FormControl ml={4}>
            <FormLabel mb={1} htmlFor='region'>{t('REGION')}</FormLabel>
            <Select id='region' value={regionDropdown} onChange={e => setRegionDropdown(e.target.value)} cursor={'pointer'}>
              {
                regionSelect.map(item => <option value={item.key}>{item.translate}</option>)
              }
            </Select>
          </FormControl>
          <FormControl ml={4}>
            <FormLabel mb={1} htmlFor='bot_software'>{t('BOT_SOFTWARE')}</FormLabel>
            <Select id='bot_software' value={botDropdown} onChange={e => setBotDropdown(e.target.value)} cursor={'pointer'}>
              {
                botSelect.map(item => <option value={item.key}>{item.translate}</option>)
              }
            </Select>
          </FormControl>
        </Box>
        <RadioGroup onChange={val => setGame(val)} value={game} mb={4}>
          <Stack direction='row'>
            <Radio value='sc2'>{t('STARCRAFT2')}</Radio>
            <Radio value='overwatch'>Overwatch</Radio>
          </Stack>
        </RadioGroup>
        <Box display={'flex'} mb={4}>
          <FormControl>
            <FormLabel mb={0} htmlFor='country'>{t('TEXT_BLOCKS')}</FormLabel>
            <Text mb={2} fontSize={'xs'}>{t('TEXT_BLOCKS_INFO_1')} <span style={{ color: 'red' }}>{t('TEXT_BLOCKS_INFO_2')}</span>. {t('TEXT_BLOCKS_INFO_3')}</Text>
            {
              game === 'overwatch' &&
              <RadioGroup onChange={val => setPlatformSelect(val)} value={platformSelect} mb={4}>
                <Stack direction='row'>
                  { platformSelectOverwatch.map(platform => <Radio value={platform.key}>{platform.translate}</Radio>) }
                </Stack>
              </RadioGroup>
            }
            {
              tags.map(({ key }) => <Tag key={key} m={1} userSelect={'none'} cursor={'pointer'} onClick={() => setBuildingBlockContent(insertTag(buildingBlockContent, key, inputGenerator))} title={t(key + '_INFO')}>{t(key + '_DESC')}</Tag>)
            }
            <Generator content={buildingBlockContent} setContent={setBuildingBlockContent} generate={generate} inputRef={inputGenerator}/>
            <Text>{t('OUTPUT')}</Text>
            <Text mb={2} fontSize={'xs'}>{t('OUTPUT_INFO_1')} <span style={{ color: 'red' }}>{t('OUTPUT_INFO_2')}</span>.</Text>
            <Text>{getExampleText}</Text>
          </FormControl>
        </Box>
        <ConfigurationFooter urlResult={urlResult} urlTest={urlTest}/>
      </Box>
    </div>
  )
}