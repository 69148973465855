import React, { useState, useMemo, useEffect, createRef } from 'react'
import { Box, Input, Text, FormLabel, FormControl, Select, Tag, RadioGroup, Radio, Stack } from '@chakra-ui/react'
import { TwitchChatPreview } from 'components/TwitchChatPreview'
import { botSelect, leagueOfLegendsTags, teamfightTacticsTags, focusInput, insertTag } from 'utils/Utils'
import { Generator } from './Generator'

import * as Styles from 'styles/general'
import * as Config from 'config/config'

import { useTranslation } from 'react-i18next'
import { ConfigurationFooter } from './ConfigurationFooter'

export const RiotGames = () => {

  const { t } = useTranslation()

  const [game, setGame] = useState('lol')
  const [tags, setTags] = useState(leagueOfLegendsTags)
  const [summonerName, setSummonerName] = useState('')
  const [botDropdown, setBotDropdown] = useState(botSelect[0].key)
  const [buildingBlockContent, setBuildingBlockContent] = useState('')
  const [urlResult, setUrlResult] = useState('')
  const [urlTest, setURLTest] = useState('')
  const input = createRef<HTMLInputElement>()
  const inputGenerator = createRef<HTMLInputElement>()

  const generate = () => {
    if (summonerName !== '') {
      const bot = botSelect.find(bot => bot.key === botDropdown)
      const url = `${Config.serverUrl}?game=${game}&name=${encodeURIComponent(summonerName)}&content=${encodeURIComponent(buildingBlockContent)}`
      setURLTest(url)
      setUrlResult(bot!.syntax(url))
    }
    else {
      focusInput(input)
    }
  }

  useEffect(() => {
    setTags({ 'lol': leagueOfLegendsTags, 'tft': teamfightTacticsTags }[game]!)
    setUrlResult('')
    setBuildingBlockContent('')
  }, [game])

  useEffect(() => {
    focusInput(input)
    // eslint-disable-next-line
  }, [])

  const getExampleText = useMemo(() => {
    let temporary = buildingBlockContent
    tags.forEach(({ key, example }) => {
      temporary = temporary.replaceAll('$' + key, example)
    })
    return (
      <TwitchChatPreview text={temporary} />
    )
  }, [buildingBlockContent, tags])

  return (
    <div className={Styles.container}>
      <Box width={[400, 500, 600]} display={'flex'} flexDirection={'column'} p={'2rem'} backgroundColor='white' borderRadius='1rem'>
        <Box display={'flex'} mb={4}>
          <Box>
            <Text mb={1}>{t('SUMMONER_NAME')}</Text>
            <Input
              value={summonerName}
              onChange={e => setSummonerName(e.target.value)}
              placeholder={'Mr TrustMe'}
              width={'auto'}
              ref={input}
            />
          </Box>
          <FormControl ml={4}>
            <FormLabel mb={1} htmlFor='country'>{t('BOT_SOFTWARE')}</FormLabel>
            <Select id='information' value={botDropdown} onChange={e => setBotDropdown(e.target.value)} cursor={'pointer'}>
              {
                botSelect.map(item => <option value={item.key}>{item.translate}</option>)
              }
            </Select>
          </FormControl>
        </Box>
        <RadioGroup onChange={val => setGame(val)} value={game} mb={4}>
          <Stack direction='row'>
            <Radio value='lol'>{t('LOL')}</Radio>
            <Radio value='tft'>{t('TFT')}</Radio>
          </Stack>
        </RadioGroup>
        <Box display={'flex'} mb={4}>
          <FormControl>
            <FormLabel mb={0} htmlFor='country'>{t('TEXT_BLOCKS')}</FormLabel>
            <Text mb={2} fontSize={'xs'}>{t('TEXT_BLOCKS_INFO_1')} <span style={{ color: 'red' }}>{t('TEXT_BLOCKS_INFO_2')}</span>. {t('TEXT_BLOCKS_INFO_3')}</Text>
            {
              tags.map(({ key }) => <Tag m={1} userSelect={'none'} cursor={'pointer'} onClick={() => setBuildingBlockContent(insertTag(buildingBlockContent, key, inputGenerator))} title={t(key + '_INFO')}>{t(key + '_DESC')}</Tag>)
            }
            <Generator content={buildingBlockContent} setContent={setBuildingBlockContent} generate={generate} inputRef={inputGenerator}/> 
            <Text>{t('OUTPUT')}</Text>
            <Text mb={2} fontSize={'xs'}>{t('OUTPUT_INFO_1')} <span style={{ color: 'red' }}>{t('OUTPUT_INFO_2')}</span>.</Text>
            <Text>{getExampleText}</Text>
          </FormControl>
        </Box>
        <ConfigurationFooter urlResult={urlResult} urlTest={urlTest}/>
      </Box>
    </div>
  )
}