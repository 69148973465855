import React from 'react'
import { Box, Image, } from '@chakra-ui/react'
import Particles from "react-tsparticles"
import './ParticleImage.css'

export enum particleThemeType {
  'nature', 
  'fire', 
  'water'  
}
interface ParticleImageDef {
  imageSrc: string,
  imageAlt?: string,
  particleTheme: particleThemeType
  props?: any
}

export const ParticleImage = ({ imageSrc, imageAlt, props, particleTheme }: ParticleImageDef) => {

  return (

    <Box {...props} className='particleImageBox' display='flex' alignItems='center' justifyContent='center'>
      <Image
        width={'100%'}
        src={imageSrc}
        alt={imageAlt}
        maxWidth='80%'
        maxHeight='80%'
        objectFit='contain'
        padding={4}
      />

      <Particles
      id={(Math.random() * 1000).toString()}
        className='particles'
        options={{
          fullScreen: {
            enable: false,
            zIndex: 0
          },
          particles:
          {
            number: {
              value: 400, density: {
                enable: true, value_area: 500
              }
            }, color: {
              value: { [particleThemeType.nature]: '#2dc063', [particleThemeType.fire]: "#f00000", [particleThemeType.water]: '#3662e7' }[particleTheme]
            }, shape: {
              type: "circle", stroke: {
                width: 2,
                color: { [particleThemeType.nature]: '#54b66d', [particleThemeType.fire]: "#f07000", [particleThemeType.water]: '#509cff' }[particleTheme]
              }, polygon: {
                nb_sides: 3
              }
            }, opacity: {
              value: 0.5, random: true, anim: {
                enable: false, speed: 2, opacity_min: 0.2, sync: false
              }
            }, size: {
              value: 2, random: true, anim: {
                enable: true, speed: 3, size_min: 0, sync: false
              }
            }, line_linked: {
              enable: false, distance: 500, color: "#ffffff", opacity: 0.4, width: 2
            }, move: {
              enable: true, speed: 3, direction: "top", random: true, straight: false, out_mode: "out", bounce: false, attract: {
                // "enable": false, "rotateX": 600, "rotateY": 1200
              }
            }
          }, retina_detect: true
        }}
      />
    </Box>
  )
}