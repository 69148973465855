
import { Flex, Text, Select } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const Header = () => {

  const { i18n } = useTranslation()

  return (
    <Flex id='navbar' width={'100%'} alignItems={'center'} justifyContent={'space-between'} padding={6}>
      <Link id='logo' to='/'><Text id='logo_name' fontSize='4xl' color='#fff'>STATS4U<Text id='logo_tld' as='span' fontSize='xl' color='#fff'>.xyz</Text></Text></Link>

      <Select bg='white' variant='outline' width='6rem' onChange={(event) => { i18n.changeLanguage(event.target.value) }} value={i18n.language} defaultValue='en'>
        <option value='en'>EN</option>
        <option value='de'>DE</option>
      </Select>
    </Flex>
  )
}